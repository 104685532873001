<template>
  <div class="content-OPGlobal">
    <div  class="content-global">
      <div ref="searchOCGlobal">
        <header-component-global sTextTitle="Especialista" sTextBtnAdd="Crear operación +"
          :bAdminPermissions="bAdminOPPending" :toVieworModal="true" setDialog="AddOperation"
          @setRangeDate="setRangeDate" />
        <o-p-dashboard-component-global :oItemDashboard="oItemDashboard" @setRangeDate="setRangeDate" />
      </div>
      <o-p-component-global :aHeaders="aHeaders" :sApiGetOC="sApiGetOC" :sTypeItem="2"
        @setItemDashboard="setItemDashboard" sTextTitle="Operaciones pendientes"
        sLabelNameFilter="Buscador de operación pendiente" sPlaceholderName="Ingrese folio, nombre…"
        :oDateRange="oDateRange" />
    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      aHeaders: [
        {
          text: "Folio",
          sortable: false,
          value: "sReference",
          class: "global-header-table first-col-tb",
          cellClass: "global-body-table first-col-tb",
        },
        {
          text: "Tipo de operación",
          sortable: false,
          value: "sOrderType",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "sActorName",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Placa del vehículo",
          sortable: false,
          value: "sDriverLicensePlate",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Estado",
          sortable: false,
          value: "sStateName",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Fecha de creación",
          sortable: false,
          value: "tCreatedAt",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      sApiGetOC: "",
      oDateRange: {},
      oItemDashboard: {}
    };
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  beforeMount() {
    this.$store.commit("setTabPositionOrderPayable", 0)
    this.setApiGetOC();
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchOCGlobal !== undefined) {
          this.heightMax = this.$refs.searchOCGlobal.clientHeight;
          this.heightMax = this.heightMax + 143; //123 IS NAVBAR
        }
        if (window.innerWidth > 600) {
          // this.screenHeight = window.innerHeight - this.heightMax;
          this.screenHeight = window.innerHeight - 100;
        } else {
          this.screenHeight = window.innerHeight - 100;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setApiGetOC() {
      this.sApiGetOC = `${URI}/api/sp/v1/orders/specialist/`;
    },
    setItemDashboard(oItemDashboard) {
      this.oItemDashboard = oItemDashboard
    },
    setRangeDate(oDateRange) {
      this.oDateRange = oDateRange
    },

  },
  computed: {
    bShowOPPending() {
      return this.$store.state.bShowOPPending;
    },
    bAdminOPPending() {
      return this.$store.state.bAdminOPPending;
    },
  },
};
</script>

<style>
</style>